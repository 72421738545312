<template>
  <v-dialog v-model="dialog" width="700" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ getSafe(() => user.name) }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-container v-if="user">
        <v-alert class="mx-2 mt-2" dense :type="blocklisted ? 'success' : 'info'" outlined>{{ alertMessage }}</v-alert>
        <v-card-title class="pt-2 pb-0">{{ `ID: ${user._id}` }}</v-card-title>
        <v-card-title class="pt-0">{{ `Documento: ${user.document_no}` }}</v-card-title>
        <v-divider />
        <v-card-actions class="px-2 pt-4">
          <v-row class="pa-0">
            <v-btn :disabled="blocklisted" class="ma-2" color="error" outlined @click="usersBlocklist('add')"
              ><v-icon class="mr-1">mdi-account-remove</v-icon> Inserir Usuário na Blocklist</v-btn
            >
            <v-btn :disabled="!blocklisted" class="ma-2" color="primary" outlined @click="usersBlocklist('remove')"
              ><v-icon class="mr-1">mdi-account-plus</v-icon> Remover Usuário da Blocklist</v-btn
            >
            <tooltip-button
              color="error"
              :editable="true"
              class-props="ma-2"
              label="Excluir Usuário"
              tip="Este usuário será excluído da tabela de usuários e inserido na tabela 'users_backup'"
              @action="excludeUser"
            />
            <v-btn class="ma-2" color="primary" outlined @click="$emit('close')"><v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { QUERY_GET_USER_FROM_BLOCKLIST, MUTATION_REMOVE_USER, MUTATION_BLOCKLIST_USER } from '@/modules/accounts/graphql'

export default {
  components: {
    TooltipButton: () => import('@/components/base/TooltipButton')
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    blocklisted: null
  }),
  computed: {
    alertMessage() {
      if (this.blocklisted) return 'Usuário consta na blocklist.'
      return 'Usuário não consta na blocklist.'
    }
  },
  async mounted() {
    await this.getUserFromBlocklist()
  },
  methods: {
    async getUserFromBlocklist() {
      try {
        const { data } = await this.$apollo.query({
          query: QUERY_GET_USER_FROM_BLOCKLIST,
          fetchPolicy: 'network-only',
          variables: { account_id: this.accountId, document_no: this.user.document_no }
        })
        this.blocklisted = data.checkUserBlocklist
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao buscar usuário na blocklist', snackbarColor: '#F44336' })
      }
    },
    async excludeUser() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_USER,
          variables: {
            account_id: this.accountId,
            document_no: this.user.document_no
          }
        })
        this.$emit('close')
        this.$emit('closeAndUpdate')
        this.$snackbar({ message: 'Usuário removido com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover usuário', snackbarColor: '#F44336' })
      }
    },
    async usersBlocklist(flag) {
      const verbsMessage = []
      if (flag === 'add') verbsMessage.push('inserido', 'inserir')
      if (flag === 'remove') verbsMessage.push('removido', 'remover')

      const variables = {
        account_id: this.accountId,
        document_no: this.user.document_no,
        flag
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_BLOCKLIST_USER,
          variables
        })

        this.$emit('closeAndUpdate')
        this.$snackbar({ message: `Usuário ${verbsMessage[0]} com sucesso`, snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: `Falha ao ${verbsMessage[1]} usuário da blocklist`, snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
